import { cx } from 'utils/cx'
import { ClientLink } from 'utils/link/client'
import { ServerLink } from 'utils/link/server'

import TEST_IDS from '../../constants/testIds'

import type { CreateComponentsConfig } from '../../types'
import styles from '../GenericStyles.module.scss'

type LinkProps = CreateComponentsConfig['linkProps']

interface AnchorProps extends React.HTMLAttributes<HTMLAnchorElement> {
	readonly children: React.ReactNode
	readonly href: string
	readonly isClient?: boolean
	readonly customProps?: LinkProps
}

/** Renders a link with the href provided as a link and the literal text */
const Anchor = ({ children, href, isClient, customProps }: AnchorProps) => {
	const { withLegacyLocale, className, small, ...restProps } = customProps ?? {}
	const linkProps = {
		...restProps,
		className: cx(small ? styles.small : styles.anchor, className),
	} satisfies LinkProps

	if (isClient) {
		return (
			<ClientLink
				href={href}
				withLegacyLocale={withLegacyLocale}
				linkProps={{ 'data-testid': TEST_IDS.LINK, ...linkProps }}
				{...restProps}
			>
				{children}
			</ClientLink>
		)
	}

	return (
		<ServerLink
			href={href ?? ''}
			withLegacyLocale={withLegacyLocale}
			linkProps={{ 'data-testid': TEST_IDS.LINK, ...linkProps }}
			{...restProps}
		>
			{children}
		</ServerLink>
	)
}

export default Anchor
